.headerBox{
  box-shadow: 0 2px 18px 0 rgba(182,178,178,.5);
  height: 6rem;
  margin: 0;
  // position: fixed;
  // float: left;
  // top: 0;
  // width: 100%;
  .headerLogo{
    height: 5rem;
    margin: auto;
    display: block;
  }
}

.catalogList{
  padding-top: 2rem;
  padding-bottom: 4rem;
  .catalogBox{
    box-shadow: 0 2px 18px 0 rgba(182,178,178,.5);
    padding:1rem;
    margin-bottom: 1rem;
    .catalogImg{
      // height: 8rem;
      width: 100%;
      margin: auto;
      display: block;
    }
    .itemTitle{
      font-size: 0.9rem;
      font-weight: 600;
      padding-top: 1rem;
      text-align: justify;
    }
    .itemPrice{
      font-size: 0.9rem;
      padding-top: 0.5rem;
      text-align: center;
      padding-bottom: 0.5rem;
      font-weight: 500;
      .moq{
        border: 1px solid #000;
        padding: 0.5rem;
        // margin-top: 0.5rem;
      }
      .rate{
        border: 1px solid #000;
        padding: 0.5rem;
        // margin-left: 1rem;
      }
    }
    .itemMoq{
      font-size: 0.8rem;
    }
  }
}

.footerBox{
  background-color: #FDD835;
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  .iconBox{
    position: relative;
    float: left;
    width: 33.33%;
    .actionIcon{
      height: 2.5rem;
      margin: auto;
      display: block;
      padding: 0.5rem;
    }
  }
  
}
.borderRight{
  border-right: 1px solid gainsboro;
}
.m-t-10{
  margin-top: 10px;
}